import { useDispatch, useSelector } from "react-redux";
import { RootState, ThunkDispatchType } from "../../../store";
import { 
  fetchAnalyticsEventCategories, fetchAnalyticsEventTypes, fetchAnalyticsExecutiveContactCategories 
} from "../../../store/eventAnalytics/actions";
import { useMountEffect } from "../../../utils/hooks";
import AnalyticsTab from "./AnalyticsTab";


const AnalyticsTabContainer = () => {
  const user = useSelector((state: RootState) =>
    state.auth.user);
  const dispatch = useDispatch<ThunkDispatchType>();

  useMountEffect(() => {
    if (user?.features.CALENDAR_ANALYTICS && !user?.features.BLOCK_CALENDAR_ANALYTICS) {
      dispatch(fetchAnalyticsEventCategories());
      dispatch(fetchAnalyticsEventTypes());
      dispatch(fetchAnalyticsExecutiveContactCategories());
    }
  });

  return (
    <AnalyticsTab/>
  );
};

export default AnalyticsTabContainer;